
import { defineComponent, reactive } from 'vue'

interface DataContentCategory {
    //一级栏目数组
    categroyLevelOneList : Array<Object>;
    //背景色class数组
    cardBackColorClass : Array<String>;
    //重要程度数组
    importLevelList : Array<Object>;
    //栏目使用频率（用于筛选栏目）
    ofent : number

}

export default defineComponent({
    setup() {
        const data : DataContentCategory = reactive({
            categroyLevelOneList : [
                 
            ],
            cardBackColorClass : ['type1_class', 'type2_class', 'type3_class', 'type4_class'],
            importLevelList : [
                {value:0,label:'全部'},
                {value:1,label:'常用'},
                {value:2,label:'不常用'},
                {value:3,label:'几乎不使用'},
                {value:4,label:'废弃'},
            ],
            ofent : 0
        })
        return {data}
    },
})
