
import { defineComponent, onMounted, reactive } from 'vue'
import ListCoupon from './list-coupon.vue'
import BatchCoupon from './batch-coupon.vue'
import AddBatchCoupon from './add-batch-coupon.vue'

interface DataMainCoupon{
    //当前页面的类型
    type:string,
    //批次id
    batchId:string,
    //更改页面类型
    changeType:(type:string)=>void,
    //修改批次id
    changeBatchId:(batchId:string)=>void,
}
export default defineComponent({
    setup(props, {emit}) {
        //初始化卡券内容
        onMounted(() => {
            console.log(props)
        })
        const data : DataMainCoupon = reactive({
            type:'coupon',
            batchId:'',
            changeType(type:string){
                if(type == 'batchAdd'){
                    data.batchId = ''
                }
                data.type = type
            },
            changeBatchId(batchId:any){
                data.batchId = batchId
            }
        })
        return {data}
    },
    components: {ListCoupon,BatchCoupon,AddBatchCoupon}
})
