import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eaa7d49c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home_navigation" }
const _hoisted_2 = ["onClick", "onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.navs, (nav, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["home_navigation_li", index == _ctx.data.choseOne? 'li_chose':'']),
          key: nav,
          onClick: ($event: any) => (_ctx.data.chose(index)),
          onMouseover: ($event: any) => (_ctx.data.mouseover(index))
        }, _toDisplayString(nav.name), 43, _hoisted_2))
      }), 128))
    ])
  ]))
}