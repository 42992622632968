
import { defineComponent, reactive } from 'vue'
import MainActive from './active/main-active.vue'
import MainCoupon from './coupon/main-coupon.vue'
import MainPoint from './point/main-point.vue'
import MainMember from './member/main-member.vue'

interface DataMainMarket{

}
export default defineComponent({
    setup() {
        const data : DataMainMarket = reactive({
            
        })
        return {data}
    },
    components:{MainActive, MainCoupon, MainPoint, MainMember}
})
