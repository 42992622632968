
import { defineComponent, onMounted, reactive } from 'vue';
import {Plus} from '@element-plus/icons';
import addCategory from './add-category.vue';
import detailCategory from './detail-category.vue';
import {list, tree, detail} from '@/http/api/category'

interface DataCategory {
    //栏目树
    categoryTree : Array<Object>,
    //展示结构
    defaultProps : Object,
    //点击栏目时触发事件
    drop : (category:{value:string}) => void,
    //打开dialog时去后台加载栏目详情信息
    getCategoryDetail : () => void,
    //选中的栏目详情
    detail : Object,
    //当前选中的栏目id
    selectOne : string,
    //新增栏目抽屉是否打开
    addCategoryDrawer : boolean,
    //页面类型
    pageType : string,
    //新增栏目
    addCategory : () => void,
    //跳到主页
    toMain : () => void,
    //跳回首页并刷新数据
    flashCategory : () => void,
    //编辑栏目
    edit : () => void,
    //关闭新增栏目抽屉
    addDrawerClose : () => void,
}
export default defineComponent({
    setup() {
        onMounted(() =>{
            let req = {
                parentId: 0
            }
            tree(req).then((res)=>{
                data.categoryTree = res.data.data
            })
        })
        const data : DataCategory = reactive({
            pageType : "main",
            categoryTree : [],
            defaultProps : {
                children : 'children',
                label : 'label'
            },
            drop : (category) => {
                data.selectOne = category.value
                let req = {
                    id: data.selectOne
                }
                detail(req).then((res) => {
                    data.detail = res.data.data
                    data.pageType = 'detail'
                })
            },
            //当前选中的栏目id
            selectOne : '',
            detail : {

            },
            //打开dialog时去后台加载栏目详情信息
            getCategoryDetail : () => {

            },
            //设置抽屉默认值
            addCategoryDrawer : false,
            //打开抽屉
            addCategory : () =>{
                data.addCategoryDrawer = true;
            },
            toMain : () => {
                data.pageType = 'main'
            },
            flashCategory : () => {
                console.log("2222222")
                let req = {
                    parentId: 0
                }
                tree(req).then((res)=>{
                    data.categoryTree = res.data.data
                    data.pageType = "main"
                })
            },
            edit:()=>{
                data.addCategoryDrawer = true
            },
            addDrawerClose : () => {
                data.detail = {}
            }
        })
        return {data}
    },
    components : {Plus,addCategory,detailCategory}
})
