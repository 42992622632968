
import { defineComponent, reactive } from 'vue';
import {Delete} from '@element-plus/icons';
import { ElMessage } from 'element-plus';
import {batchAddTag} from '@/http/api/tag'

interface DataAddTag {
    //默认标签内容
    defaultTag : Object;
    //表单内容
    tags : Array<Object>;
    //标签类型列表
    types : Array<Object>;
    //新增标签方法
    add : () => void;
    //删除标签方法
    del : (index:number) => void;
    //保存标签
    save : () => void;
}
export default defineComponent({
    emits: ['closeDrawer'],
    setup(props,context) {
        const data : DataAddTag = reactive({

            defaultTag : {
                    name : '',
                    type : 1,
                    msg : ''
                },
            tags : [
                {
                    name : '',
                    type : 1,
                    msg : ''
                },
            ],
            types : [
                {id:1,name:'人员'},
                {id:0,name:'内容'}
            ],
            add : () => {
                data.tags.push(
                    {
                        name : '',
                        type : 1,
                        msg : '简介'
                    }
                )
            },
            del : (index:number) => {
                data.tags.splice(index,1)
            },
            save : () => {
                batchAddTag(data.tags).then((res) => {
                    if (res.data) {
                        context.emit('closeDrawer')
                    }
                }).catch((e) => {
                    ElMessage({
                            showClose: true,
                            message: e.errorMessage,
                            type: 'error',
                        })
                })
            }

        })
        return {data}
    },
    components : {Delete}
})
