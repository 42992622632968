
import { defineComponent, onMounted, reactive } from 'vue'
import {detailActive} from '@/http/api/currency'


interface DataDetailActive{
    //活动id
    activeId:Object,
    //活动
    active:any,
    //跳转主页
    toTable:()=>void,
}
export default defineComponent({
    props : ["activeId"],
    emits:["changeType"],
    setup(props,{emit}) {
        onMounted(() =>{
            //活动id
            data.activeId = props.activeId
            //获取活动详情
            detailActive({id:props.activeId}).then((res) => {
                if (res.data.success && res.data.data !== null) {
                    data.active = res.data.data
                    //处理时间
                    data.active.time = res.data.data.startTime + " - " + res.data.data.endTime
                }
            })
        })
        const data : DataDetailActive = reactive({
            activeId:'',
            active:{},
            toTable(){
                emit("changeType","table")
            }
        })
        return {data}
    },
})
