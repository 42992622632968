
import {Plus} from '@element-plus/icons';
import { defineComponent, reactive } from 'vue';
import mainContentCategory from './main-content-category.vue';
import MainContentTable from './main-content-table.vue';
import AddContent from './add-content.vue';
import ContentDetail from './content-detail.vue';

interface DataContent {
    //开关是否打开
    switchParam : boolean;
    //是否新增
    addOpen : boolean;
    //是否打开详情
    detailOpen : boolean;
    //选中要操作的内容的id
    contentId : string;
    //当前所处页面的类型
    pageType : string;
    //返回首页
    toMain : () =>void;
    //新增方法
    addContent : () => void;
    //关闭新增页面
    closeAdd : () => void;
    //编辑内容时从子组件传的值
    editContentId : (contentId:any) => void;
    //获取用户详情
    detailContentId : (contentId:any) => void;
}
export default defineComponent({
  components: { mainContentCategory, MainContentTable, Plus, AddContent, ContentDetail },
    setup() {
        const data : DataContent = reactive({
            switchParam : false,
            addOpen: false,
            detailOpen: false,
            contentId:'',
            pageType: 'home',
            toMain : () => {
                data.pageType = 'home',
                data.detailOpen = false
            },
            addContent : () => {
                data.addOpen = true
            },
            closeAdd : () => {
                data.addOpen = false
            },
            editContentId : (contentId:any) => {
                data.contentId = contentId
                data.addOpen = true
            },
            detailContentId : (contentId:any) => {
                data.contentId = contentId
                data.detailOpen = true
                data.pageType = 'detail'
            }
        })
        return {data}
    },

})
