
import { defineComponent, onMounted, reactive } from 'vue'

interface DataMainMember{

}
export default defineComponent({
    setup(props,{emit}) {
        //初始化会员内容
        onMounted(() => {
            console.log(props)
        })
        const data : DataMainMember = reactive({
            
        })
        return {data}
    },
})
