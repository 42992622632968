
import { defineComponent, onMounted, reactive } from 'vue';
import { Plus, Download, Delete, ZoomIn } from '@element-plus/icons';
import { ElLoading, ElMessage } from 'element-plus';
import resInfo from '@/store/modules/userInfo';
import {uploadUrl} from '@/utils/url'



interface DataContentUnit{
    //模板对象
    unit:{
        value:any
    };
    //单元展示类型
    unitType:string;
    //组件返回的值
    contentUnit:Object;
    //图片上传url
    uploadUrl:string;
    //图片列表
    coversList:Array<Object>;
    //图片数量限制
    limitCountImg:number;
    //是否显示详情按钮
    showBtnDealImg:boolean,
    //上传按钮是否隐藏
    noneBtnImg:boolean;
    //操作是否显示
    disabled:boolean;
    //展示图片的url
    dialogImageUrl:string;
    //展示图片信息
    dialogVisible: boolean;
    //上传图片时列表
    fileList: Array<any>
    //上传图片之前的操作
    beforeImageUpload:(file:any) => void;
    //删除图片
    removePic:(file:any) =>void;
    //图片上传成功回调
    uploadSuccess:(response:any,file:any,fileList:any) => void;
    //图片触发上限
    handleExceed:(file:any,fileList:any) => void;
    //发生变化时触发
    handleImgChange:(file:any,fileList:any) => void;
    //提交结果
    submit:() => void;

}
export default defineComponent({
    props:[
        'unitType',
        'unit'
    ],
    setup(props,{emit}) {
        onMounted(() =>{
            data.unit =  JSON.parse(JSON.stringify(props.unit)),
            data.unitType = props.unitType
        })
        const data : DataContentUnit = reactive({
            unit:{
                value: {}
            },
            unitType:'',
            contentUnit:{},
            uploadUrl:uploadUrl.getUploadUrl(),
            coversList:[],
            limitCountImg:9,
            disabled:false,
            showBtnDealImg:true,
            noneBtnImg:false,
            dialogVisible:false,
            dialogImageUrl:"",
            fileList:[],
            beforeImageUpload:(file) =>{
                //校验图片大小
                if(file.size / 1024 / 1024 > 10){
                    ElMessage.error("单张图片大小不能超过10MB!");
                    return false;
                }
                return true;
            },
            removePic:(file) =>{
                //移出列表中的图片
                if (data.fileList.length > 0) {
                    data.fileList.forEach((item,index) =>{
                        if (item.name == file.name) {
                            data.fileList.splice(index,1)
                        }
                    })
                }
            },
            uploadSuccess:(response, file, fileList) => {
                //赋值
                if (response.errorCode == 200 && response.data !='') {
                    //构建列表参数
                    let pic = {
                        name:'',
                        url:''
                    }
                    pic.name = file.name
                    pic.url = response.data
                    data.fileList.push(pic)
                    console.log(pic)
                }else{
                    ElMessage.error({
                        message: '图片上传失败！',
                        type:"error"
                    })
                }
            },
            handleExceed: (file,fileList) => {
                ElMessage.error({
                    message: `上传图片数量超出限制！`,
                    type: "error",
                });
            },
            handleImgChange:(file,fileList) =>{
                data.noneBtnImg = fileList.length >= data.limitCountImg;
            },
            submit:() => {
                //判断是否时图片上传
                if (data.fileList.length > 0) {
                    let urlList: any[] = []
                    data.fileList.forEach((item,index)=>{
                        urlList.push(item.url)
                    })
                    data.unit.value = urlList.join(",");
                    // data.unit.value = JSON.stringify(data.unit.value)
                }
                emit("getUnit",data.unit)
            }
        })
        return {data}
    },
    components:{Plus,Download,Delete,ZoomIn}
})
