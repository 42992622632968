import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_data_task_list = _resolveComponent("main-data-task-list")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_main_data_task = _resolveComponent("main-data-task")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_main_data_view = _resolveComponent("main-data-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "main_data" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "out_header" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_aside, { class: "in_aside" }, {
                default: _withCtx(() => [
                  _createVNode(_component_main_data_task_list)
                ]),
                _: 1
              }),
              _createVNode(_component_el_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_header, { class: "in_header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_main_data_task)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_main, { class: "in_main" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_main_data_view)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_main)
    ]),
    _: 1
  }))
}