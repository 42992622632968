
import { defineComponent, inject, onMounted } from 'vue';
import * as echarts from 'echarts';

export default defineComponent({
    setup() {
        //引入echarts
        onMounted(() => {
            //需要获取到element，所以是onMounted的Hook
            let temp1=document.getElementById('data_view_chart');
            //存在时在执行
            if(temp1){
                let myChat = echarts.init(temp1);
                myChat.setOption({
                    title:{
                        text: '总浏览量'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '浏览量/万',
                            type: 'line',
                            data: [5, 20, 36, 10, 10, 20, 78],
                            areaStyle: {}
                        }
                    ]
                });
            }
            let temp2 = document.getElementById("data_category_pie_chart");
            if(temp2){
                let myChat = echarts.init(temp2);
                myChat.setOption({
                    title: {
                        text: '栏目浏览量'
                    },
                    legend: {
                        data:[]
                    },
                    series: [
                        {
                            name: '浏览量/万',
                            type: 'pie',
                            radius: '50%',
                            data: [
                                { value: 0, name: '相亲' },
                                { value: 0, name: '动态' },
                                { value: 0, name: '工作' },
                                { value: 0, name: '其他' },
                                { value: 0, name: '物流' }
                            ],
                            emphasis: {
                                itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                });

            }
        })

    },
})
