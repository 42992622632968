import request from "../http";

/**
 * 批量新增标签
 * @param param 参数，内部为一个标签数组
 * @returns 是否新增成功
 */
export const batchAddTag = (param:any) =>{
    return request({
        url : "/by-content/tag/batchAdd",
        method : "post",
        data : param
    })
}

/**
 * 修改标签信息
 * @param param 修改参数
 * @returns 是否修改成功
 */
export const updateTag = (param:any) =>{
    return request({
        url : "/by-content/tag/update",
        method : "post",
        data : param
    })
}

/**
 * 删除标签信息
 * @param param 标签id（在一个对象内部）
 * @returns 是否删除成功
 */
export const delTag = (param:any) =>{
    return request({
        url : "/by-content/tag/del",
        method : "post",
        data : param
    })
}

/**
 * 获取标签详情
 * @param param 标签id（在一个对象内部）
 * @returns 标签详情信息
 */
export const detailTag = (param:any) =>{
    return request({
        url : "/by-content/tag/detail",
        method : "post",
        data : param
    })
}

/**
 * 查询标签列表
 * @param param 查询参数
 * @returns 标签列表
 */
export const queryTag = (param:any) =>{
    return request({
        url : "/by-content/tag/query",
        method : "post",
        data : param
    })
}