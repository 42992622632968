import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05c27497"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home_main" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }
const _hoisted_10 = { key: 8 }
const _hoisted_11 = { key: 9 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_data = _resolveComponent("main-data")!
  const _component_main_category = _resolveComponent("main-category")!
  const _component_main_template = _resolveComponent("main-template")!
  const _component_main_content = _resolveComponent("main-content")!
  const _component_main_video = _resolveComponent("main-video")!
  const _component_main_tag = _resolveComponent("main-tag")!
  const _component_main_resource = _resolveComponent("main-resource")!
  const _component_main_market = _resolveComponent("main-market")!
  const _component_main_system = _resolveComponent("main-system")!
  const _component_main_charge = _resolveComponent("main-charge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (0==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_main_data)
        ]))
      : _createCommentVNode("", true),
    (1==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_main_category)
        ]))
      : _createCommentVNode("", true),
    (2==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_main_template)
        ]))
      : _createCommentVNode("", true),
    (3==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_main_content)
        ]))
      : _createCommentVNode("", true),
    (4==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_main_video)
        ]))
      : _createCommentVNode("", true),
    (5==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_main_tag)
        ]))
      : _createCommentVNode("", true),
    (6==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_main_resource)
        ]))
      : _createCommentVNode("", true),
    (7==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_main_market)
        ]))
      : _createCommentVNode("", true),
    (8==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_main_system)
        ]))
      : _createCommentVNode("", true),
    (9==_ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_main_charge)
        ]))
      : _createCommentVNode("", true)
  ]))
}