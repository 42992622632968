
import { defineComponent, onMounted, reactive } from 'vue'
import {queryCoupon,getCouponType} from '@/http/api/currency'

interface DataListCoupon{
    //查询条件
    query:Object
    //卡券列表
    coupons:Array<Object>
    //卡券类型
    couponTypes:Array<Object>
    //搜索
    select:()=>void
    //跳转卡券批次
    toBatch:()=>void
}
export default defineComponent({
    emits:["changeType"],
    setup(props,{emit}) {
        onMounted(() => {
            //查询卡券信息
            queryCoupon({}).then((res) => {
                if (res.data.success && res.data.data !== null) {
                    data.coupons = res.data.data
                }
            })
            //获取卡券类型
            getCouponType().then((res) => {
                if (res.data.success && res.data.data !== null) {
                    data.couponTypes = res.data.data
                }
            })
        })
        const data:DataListCoupon = reactive({
            query:{},
            coupons:[],
            couponTypes:[],
            select(){
                console.log(data.query)
                queryCoupon(data.query).then((res) => {
                    if (res.data.success && res.data.data !== null) {
                        data.coupons = res.data.data
                    }
                })
            },
            toBatch(){
                emit("changeType","couponBatch")
            }
        })
        return {data}
    },
})
