
import { defineComponent, reactive } from 'vue';

interface DataNav {
    //导航数据
    navs : Array<Object>;
    //当前选中的栏目（下标）
    choseOne : number;
    //选中事件
    chose:(index:number) => void;
    //鼠标悬停事件
    mouseover:(index:number) => void;
}

export default defineComponent({

    props:{
        choseOne: {

        }
    },
    setup(props,{emit}) {
        const data : DataNav = reactive({
            navs : [
                {id:1,name:'数据概览'},
                {id:2,name:'栏目管理'},
                {id:3,name:'模板管理'},
                {id:4,name:'内容管理'},
                {id:5,name:'视频管理'},
                {id:6,name:'标签管理'},
                {id:7,name:'资源管理'},
                {id:8,name:'营销管理'},
                {id:9,name:'系统设置'},
                {id:10,name:'收入统计'},
            ],
            //当前选中的栏目（下标、默认为-1）
            choseOne : -1,
            //选中事件
            chose : (index:number) =>{
                data.choseOne = index;
                emit('choseNav',index)
            },
            mouseover : (index:number) => {
                
            }
        })
        return {data};
    },
    emits: ['choseNav']
})
