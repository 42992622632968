import request from "../http";

/**
 * 新增栏目
 * @param category 新增参数
 */
export const add = (category:any) =>{
    return request({
        url: '/by-content/category/add',
        method: 'post',
        data: category
    })
}

/**
 * 修改栏目
 * @param category 修改参数
 */
 export const updateCategory = (category:any) =>{
    return request({
        url: '/by-content/category/update',
        method: 'post',
        data: category
    })
}

/**
 * 删除栏目
 * @param req 新增参数
 */
 export const del = (req:any) =>{
    return request({
        url: '/by-content/category/del',
        method: 'post',
        data: req
    })
}

/**
 * 栏目列表
 * @param req 查询条件
 */
export const list = (req:any) =>{
    return request({
        url: '/by-content/category/list',
        method: 'post',
        data: req
    })
}

/**
 * 栏目树
 * @param req 查询条件
 */
export const tree = (req:any) =>{
    return request({
        url: '/by-content/category/tree',
        method: 'post',
        data: req
    })
}

/**
 * 获取栏目详情
 * @param req id
 */
export const detail = (req:any) =>{
    return request({
        url: '/by-content/category/detail',
        method: 'post',
        data: req
    })
}

/**
 * 获取所有的收费类型
 * @param req id
 */
 export const getAllChargeType = () =>{
    return request({
        url: '/by-content/category/getAllChargeType',
        method: 'post',
        data: null
    })
}