
import { defineComponent, onMounted, reactive } from 'vue';
import {time} from '@/utils/dateUtil';
import { getAllStatus, query, del, approve, changeStatus } from '@/http/api/content';
import {tree} from '@/http/api/category';
import {ElMessageBox, ElMessage} from 'element-plus';

interface DataTableContent {
    //查询参数
    params : {
        pageSize : number,
        pageNum : number,
        status : any,                
        title : string,
        categoryId : string,
    };
    //级联选择器参数
    props:Object;
    //选中的分类级联信息
    categoryIds:Array<any>
    //所有状态列表
    typeList : Array<Object>;
    //内容总数据量
    count : number;
    //内容列表
    contentList : Array<any>;
    //栏目树
    categoryTree : Array<Object>;
    //审批
    approveDialog : boolean;
    //要审批的内容id
    approveContent : Object;
    //全部审批结果
    approveRes : Array<any>;
    //详情
    handleDetail : (content:any) => void;
    //编辑
    handleEdit : (content:any) => void;
    //删除
    handleDel : (content:any) => void;
    //审批
    handleApprove : (content:any) => void;
    //下架
    handleDown : (content:any) => void;
    //查询
    selectContent : (val:number) => void;
    //审批
    approve : () => void;
    //取消审批时
    cancelApprove : () => void;
    //关闭审批界面
    handleClose : () => void;
}
export default defineComponent({
    emits:['editContentId','detailContentId'],
    setup(props,{emit}) {
        onMounted(()=>{
            //获取所有状态信息
            getAllStatus().then((res)=>{
                data.typeList = res.data.data
                data.typeList.unshift({
                    status: -1, name: '全部'
                })
            })
            //获取内容列表
            query(data.params).then((res) => {
                console.log(res)
                data.count = Number(res.data.data.total),
                data.contentList = res.data.data.list
            })
            //查询栏目树
            let req = {
                parentId: 0
            }
            tree(req).then((res) => {
                data.categoryTree = res.data.data
            })
        })
        const data : DataTableContent = reactive({
            params:{
                pageSize:10,
                pageNum: 1,
                status: null,
                title: '',
                categoryId : ''
            },
            //级联选择器参数
            props:{
                checkStrictly : true
            },
            categoryIds:[],
            typeList : [],
            count : 10,
            contentList: [],
            categoryTree: [],
            approveDialog: false,
            approveContent: {},
            approveRes:[
                {
                    value: false,
                    label: "拒绝"
                },
                {
                    value: true,
                    label: "通过"
                }
            ],
            handleDetail : (content:any) => {
                emit("detailContentId",content.id)
            },
            handleEdit : (content:any) => {
                emit("editContentId",content.id)
            },
            handleDel : (content:any) => {
                ElMessageBox.confirm(
                    '确定要删除'+content.title+'吗？',
                    '删除',
                    {
                        confirmButtonText : '确定',
                        cancelButtonText : '取消',
                        type : 'warning'
                    }
                ).then(() => {
                    del({id:content.id}).then((res) => {
                        if (res.data.data) {
                            ElMessage({
                                type:'success',
                                message: '删除成功'
                            })
                            //重新获取内容数据
                            query(data.params).then((res) => {
                                data.count = Number(res.data.data.total),
                                data.contentList = res.data.data.list
                            })
                        }else{
                            ElMessage({
                                type:'error',
                                message: '删除失败'
                            })
                        }
                    })
                })
            },
            handleApprove : (content:any) => {
                data.approveDialog = true
                data.approveContent = {
                    id: content.id, 
                    title: content.title
                }
            },
            handleDown : (content:any) => {
                console.log(content)
                let statusContent = {
                    id: content.id,
                    status: 5
                }
                changeStatus(statusContent).then((res) => {
                    if (res.data.data) {
                        query(data.params).then((res) => {
                            data.count = Number(res.data.data.total),
                            data.contentList = res.data.data.list
                        })
                    }
                })
            },
            selectContent : (val:number) => {
                data.params.pageNum = val
                //获取级联选择器的最后一个元素
                if (data.categoryIds !== null && data.categoryIds.length>0) {
                    data.params.categoryId = data.categoryIds[data.categoryIds.length-1]
                    if(data.params.categoryId == undefined){
                        data.params.categoryId = ''
                    }   
                }else{
                    data.params.categoryId = ''
                }
                query(data.params).then((res) => {
                    data.count = Number(res.data.data.total),
                    data.contentList = res.data.data.list
                })
            },
            approve : () => {
                approve(data.approveContent).then((res) => {
                    if (res.data.data) {
                        data.approveContent = {},
                        data.approveDialog = false,
                        //重新获取内容数据
                        query(data.params).then((res) => {
                            data.count = Number(res.data.data.total),
                            data.contentList = res.data.data.list
                        })
                    }else{
                        ElMessage({
                            type:'error',
                            message: '审批失败'
                        })
                    }
                })
            },
            cancelApprove : () => {
                data.approveDialog = false,
                data.approveContent = {}
            },
            handleClose : () => {
                data.approveContent = {},
                data.approveDialog = false
            }
        })
        return {data}
    },
})
