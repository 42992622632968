
import { defineComponent, onMounted, reactive } from 'vue';
import {tree, detail} from '@/http/api/category';
import {add,contentDetail,contentUpdate} from '@/http/api/content';
import ContentUnit from './content-unit.vue';
import WangEditor from './wang-editor.vue';
import { ElLoading, ElMessage } from 'element-plus';
import { update } from '@/http/api/tem';
import {uploadUrl} from '@/utils/url'

interface DataAddContent {
    ccc:string;
    //内容id
    contentId:string;
    //选择栏目时提示
    categoryMsg:string;
    //级联选择器参数
    props:Object;
    //选中的分类id
    categoryIds:Array<any>;
    //要新增或修改的内容
    content: {
        //id更新时使用
        id:string,
        //头像
        coverUrl:string,
        //内容
        content:string,
        //栏目id
        categoryId:string,
        //状态
        status:number,
        //标签
        tagIds:Array<any>,
        //单元对应的值
        contentTemUnits:Array<any>
    };
    //分类树
    categoryTree: Array<Object>;
    //栏目模板
    tem:{
        units:Array<any>
    };
    //栏目对应的标签
    tags:Array<any>;
    //图片上传url
    uploadUrl:string;
    //图片列表
    coversList:Array<Object>;
    //图片数量限制
    limitCountImg:number;
    //是否显示详情按钮
    showBtnDealImg:boolean,
    //上传按钮是否隐藏
    noneBtnImg:boolean;
    //操作是否显示
    disabled:boolean;
    //展示图片的url
    dialogImageUrl:string;
    //展示图片信息
    dialogVisible: boolean;
    //上传图片时列表
    fileList: Array<any>
    //上传图片之前的操作
    beforeImageUpload:(file:any) => void;
    //删除图片
    removePic:(file:any) =>void;
    //图片上传成功回调
    uploadSuccess:(response:any,file:any,fileList:any) => void;
    //图片触发上限
    handleExceed:(file:any,fileList:any) => void;
    //发生变化时触发
    handleImgChange:(file:any,fileList:any) => void;
    //根据选中的栏目获取模板
    getTem : (param:any) => void;
    //获取单元值
    getUnit: (unit:any) => void;
    //保存
    save: () => void;
    //提交数据
    submit: () => void;
    //获取富文本编辑器
    getValueHtml: (value:any) => void;
}
export default defineComponent({
    props:[
        'contentId'
    ],
    setup(props,{emit}) {
        onMounted(() => {
            if (props.contentId != '') {
                data.contentId = props.contentId
                contentDetail({id:data.contentId}).then((res) => {
                    data.content = res.data.data
                    data.categoryIds.push(res.data.data.categoryId)
                    data.categoryMsg = res.data.data.category.name
                    if (res.data.data.contentTemUnits !== null) {
                        data.tem.units = res.data.data.contentTemUnits
                    }
                    data.tags = []
                    if (res.data.data.category.tags !== null && res.data.data.category.tags.length > 0) {
                        for (let index = 0; index < res.data.data.category.tags.length; index++) {
                            data.tags.push({
                                id:res.data.data.category.tags[index].tagId,
                                name:res.data.data.category.tags[index].name
                            })
                        }
                    }
                    console.log(data.tags)
                    data.content.tagIds = []
                    if (res.data.data.tags !== null && res.data.data.tags.length > 0) {
                        for (let index = 0; index < res.data.data.tags.length; index++) {
                            data.content.tagIds.push(res.data.data.tags[index].id)
                        }
                    }
                })
            }else{
                //查询栏目树
            let req = {
                parentId: 0
            }
            tree(req).then((res) => {
                data.categoryTree = res.data.data
            })
            }
        })
        const data :DataAddContent = reactive({
            ccc:'',
            contentId:'',
            categoryMsg:"请选择栏目",
            //级联选择器参数
            props:{
                checkStrictly : true
            },
            categoryIds:[],
            content:{
                id:'',
                coverUrl:'',
                content:'',
                categoryId:'',
                status:0,
                tagIds:[],
                contentTemUnits:[]
            },
            categoryTree:[],
            tem:{
                units:[]
            },
            tags:[],
            uploadUrl:uploadUrl.getUploadUrl(),
            coversList:[],
            limitCountImg:1,
            disabled:false,
            showBtnDealImg:true,
            noneBtnImg:false,
            dialogVisible:false,
            dialogImageUrl:"",
            fileList:[],
            beforeImageUpload : (file:any) => {
                //校验图片大小
                if(file.size / 1024 / 1024 > 10){
                    ElMessage.error("单张图片大小不能超过10MB!");
                    return false;
                }
                return true;
            },
            removePic:(file:any) =>{
                //移出列表中的图片
                if (data.fileList.length > 0) {
                    data.fileList.forEach((item,index) =>{
                        if (item.name == file.name) {
                            data.fileList.splice(index,1)
                        }
                    })
                }
            },
            uploadSuccess:(response:any, file:any, fileList:any) => {
                //赋值
                if (response.errorCode == 200 && response.data !='') {
                    //构建列表参数
                    let pic = {
                        name:'',
                        url:''
                    }
                    pic.name = file.name
                    pic.url = response.data
                    data.fileList.push(pic)
                    console.log(pic)
                    //设置头像
                    data.content.coverUrl
                }else{
                    ElMessage.error({
                        message: '图片上传失败！',
                        type:"error"
                    })
                }
            },
            handleExceed: (file,fileList) => {
                ElMessage.error({
                    message: `上传图片数量超出限制！`,
                    type: "error",
                });
            },
            handleImgChange:(file,fileList) =>{
                data.noneBtnImg = fileList.length >= data.limitCountImg;
            },
            getTem : (param:any) => {
                if (param !== null && param.length>0) {
                    let req = {
                        id : param[param.length -1]
                    }
                    data.tags = []
                    //获取模板数据
                    detail(req).then((res) =>{
                        data.tem = res.data.data.template
                        // console.log(data.tem.units)
                        if (data.tem.units !== null && data.tem.units.length>0) {
                            for (let index = 0; index < data.tem.units.length; index++) {
                                data.tem.units[index].temUnitId = data.tem.units[index].id
                                data.tem.units[index].id = ''
                            }
                        }
                        if (res.data.data.tags !== null && res.data.data.tags.length>0) {
                            for (let index = 0; index < res.data.data.tags.length; index++) {
                                data.tags.push({
                                    id:res.data.data.tags[index].tagId,
                                    name:res.data.data.tags[index].name
                                })
                            }
                        }
                    })
                    //获取标签数据

                }else{
                    data.tem = {
                        units: []
                    }
                }
            },
            getUnit : (unit:any) => {
                console.log(unit)
                // console.log(data.contentId)
                if (data.contentId == ''||data.contentId == null) {
                    unit.id = ''
                }
                if (data.content.contentTemUnits.length > 0) {
                    console.log(data.content.contentTemUnits)
                    let addUnit = false;
                    for (let index = 0; index < data.content.contentTemUnits.length; index++) {
                        if (data.content.contentTemUnits[index].temUnitId === unit.temUnitId) {
                            data.content.contentTemUnits.splice(index,1,unit);
                            addUnit = true
                        }
                    } 
                    if (!addUnit) {
                        data.content.contentTemUnits.push(unit)
                    }  
                }else{
                    data.content.contentTemUnits.push(unit)
                }
                // console.log(data.content.contentTemUnits)
            },
            save : () => {
                if (data.categoryIds !==null && data.categoryIds.length >0) {
                    data.content.categoryId = data.categoryIds[data.categoryIds.length -1]
                }
                data.content.status = 0;
                if (data.content.id == null||data.content.id == '') {
                    add(data.content).then((res)=>{
                        if(res.data.data){
                            console.log(res.data.data)
                            emit("closeAdd")
                        }
                    })   
                }else{
                    contentUpdate(data.content).then((res)=>{
                        if(res.data.data){
                            console.log(res.data.data)
                            emit("closeAdd")
                        }
                    })
                }
            },
            submit : () => {
                if (data.categoryIds !==null && data.categoryIds.length >0) {
                    data.content.categoryId = data.categoryIds[data.categoryIds.length -1]
                }
                data.content.status = 1;
                if (data.content.id == null||data.content.id == '') {
                    add(data.content).then((res)=>{
                        if(res.data.data){
                            console.log(res.data.data)
                            emit("closeAdd")
                        }
                    })   
                }else{
                    contentUpdate(data.content).then((res)=>{
                        if(res.data.data){
                            console.log(res.data.data)
                            emit("closeAdd")
                        }
                    })
                }
            },
            getValueHtml : (value:any) => {
                data.content.content = value
            }
        })
        return {data}
    },
    components: {
        ContentUnit,
        WangEditor
    }
})
