<!-- 富文本编辑器 -->
<template>
    <div style="border: 1px solid #ccc">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
      />
    </div>
</template>


<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted, watch } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {uploadUrl} from '@/utils/url'

export default {
  components: { Editor, Toolbar },
  setup(props,{emit}) {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('<p>请输入内容...</p>')

    // 模拟 ajax 异步获取内容
    onMounted(() => {
        setTimeout(() => {
            valueHtml.value = ''
        }, 1500)
    })

    const toolbarConfig = {}
    const editorConfig = { 
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }
    editorConfig.MENU_CONF['uploadImage'] = {
        fieldName:"file",
        server: 'https://ysh.znfch.com/api/by-resource/file/upload',
        // 自定义插入图片
        customInsert(res, insertFn) {                  // JS 语法
            // res 即服务端的返回结果
            console.log(res)
            // 从 res 中找到 url alt href ，然后插入图片
            insertFn(res.data, null, null)
        },
}

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }
    
    watch(valueHtml,(newV,oldV) => {
        //处理dome
        if(newV !== null){
            newV = newV.replaceAll('style=""','')
        }
        if(newV !== null){
            emit("getValueHtml",newV)
        }
    })
    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
    };
  }
}
</script>    