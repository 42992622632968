import request from "../http";

/**
 * 更新
 */
export const upload = (req:any) =>{
    return request({
        url: '/by-content/content/upload',
        method: 'post',
        data: req
    })
}

/**
 * 新增内容
 */
export const add = (req:any) => {
    return request({
        url: '/by-content/content/add',
        method: 'post',
        data: req
    })
}

/**
 * 新增内容
 */
 export const contentUpdate = (req:any) => {
    return request({
        url: '/by-content/content/update',
        method: 'post',
        data: req
    })
}

/**
 * 删除 
 */
export const del = (req:any) => {
    return request({
        url: '/by-content/content/del',
        method: 'post',
        data : req
    })
}

/**
 * 查询
 */
export const query = (req:any) => {
    return request({
        url: '/by-content/content/page',
        method: 'post',
        data: req
    })
}

/**
 * 详情 
 */
export const contentDetail = (req:any) => {
    return request({
        url: '/by-content/content/detail',
        method: 'post',
        data: req
    })
}

/**
 * 获取所有的文章状态
 */
export const getAllStatus = () => {
    return request({
        url: '/by-content/content/getAllStatus',
        method: 'post'
    })
}

/**
 * 获取所有的文章状态
 */
 export const approve = (req:any) => {
    return request({
        url: '/by-content/content/approve',
        method: 'post',
        data: req
    })
}

/**
 * 获取所有的文章状态
 */
 export const changeStatus = (req:any) => {
    return request({
        url: '/by-content/content/changeStatus',
        method: 'post',
        data: req
    })
}

/**
 * 获取所有的视频状态
 * @returns 状态
 */
export const getAllVideoStatus = () => {
    return request({
        url: '/by-content/video/getAllStatus',
        method: 'post',
        data: null
    })
}

/**
 * 分页获取视频信息
 * @param req 
 * @returns 
 */
export const pageVideo = (req:any) => {
    return request({
        url: '/by-content/video/page',
        method: 'post',
        data: req
    })
}

export const changeVideoStatus = (req:any) => {
    return request({
        url: '/by-content/video/changeStatus',
        method: 'post',
        data: req
    })
}

/**
 * 获取所有的文章状态
 */
export const approveVideo = (req:any) => {
    return request({
        url: '/by-content/video/approve',
        method: 'post',
        data: req
    })
}

/**
 * 删除视频
 */
export const delVideo = (req:any) => {
    return request({
        url: '/by-content/video/del',
        method: 'post',
        data : req
    })
}