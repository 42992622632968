import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_select, {
        class: "content_category_select",
        modelValue: _ctx.data.ofent,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.ofent) = $event)),
        placeholder: "使用频率"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.importLevelList, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item.value,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.categroyLevelOneList, (category) => {
      return (_openBlock(), _createBlock(_component_el_card, {
        class: _normalizeClass(["category_card", _ctx.data.cardBackColorClass[category.type-1]]),
        key: category
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(category.name), 1)
        ]),
        _: 2
      }, 1032, ["class"]))
    }), 128))
  ]))
}