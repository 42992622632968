
import { defineComponent, onMounted, reactive } from 'vue';

interface DataTask {
    //正在进行的任务数量
    taskIng : number,
    //已经完成的任务数量
    taskEnd : number,
    //未完成的任务数量
    taskUn : number,
}
export default defineComponent({
    setup() {
        const data : DataTask = reactive({
            //正在进行的任务数量
            taskIng : 30,
            //已经完成的任务数量
            taskEnd : 28,
            //未完成的任务数量
            taskUn : 70,
        })
        return {data}
    },
})
