
import { number } from 'echarts';
import {add, updateCategory, getAllChargeType} from '@/http/api/category';
import {queryTag} from '@/http/api/tag';
import {query, update} from '@/http/api/tem';
import { defineComponent, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import {uploadUrl} from '@/utils/url'


interface DataAddCategory {
    //新增栏目对象
    category : {
        id : string,
        //栏目名
        name : string,
        //父级栏目
        parentId : number,
        //是否允许分享
        boolShare : boolean,
        //是否收费
        boolCharge : boolean,
        //是否允许匿名发布
        boolAnonymous : boolean,
        //收费类型
        chargeType : any,
        //收费方式
        chargeModelId : string,
        //图标地址
        iconUrl : string,
        //分类下可使用的标签
        tagIds : Array<string>,
    },
    //级联选择器参数
    checkprops:{
        checkStrictly : true
    },
    //分类树
    categoryTree : Array<Object>,
    //收费模式
    chargeModel : Array<Object>,
    //内容模板
    templates : Array<Object>,
    //父级
    pId : Array<number>,
    //所有标签信息
    tags : Array<Object>,
    //上传按钮是否隐藏
    noneBtnImg:boolean;
    //操作是否显示
    disabled:boolean;
    //展示图片的url
    dialogImageUrl:string;
    //展示图片信息
    dialogVisible: boolean;
    //图片上传url
    uploadUrl:string;
    //上限
    limitCountImg:number;
    //上传图片时列表
    fileList: Array<any>;
    //栏目收费类型
    chargeTypeList:Array<any>;
    //提交
    submit:()=>void;
    //图片触发上限
    handleExceed:(file:any,fileList:any) => void;
    //发生变化时触发
    handleImgChange:(file:any,fileList:any) => void;
    //删除图片
    removePic:(file:any) =>void;
    //图片上传成功回调
    uploadSuccess:(response:any,file:any,fileList:any) => void;
}
export default defineComponent({
    props:[
        'categoryTree',
        'detail'
    ],
    setup(props) {
        onMounted(() => {
            console.log(props.detail)
            if (props.detail !== null && JSON.stringify(props.detail) !== '{}') {
                data.category = props.detail;
                //构建列表参数
                    let pic = {
                        name:'',
                        url:''
                    }
                    pic.name = ""
                    pic.url = props.detail.iconUrl
                    data.fileList.push(pic)
            }
            query({}).then((res)=>{
                data.templates = res.data.data
            }),
            //查询所有标签数据
            queryTag({}).then((res) => {
                data.tags = res.data.data
                console.log(data.tags)
            }),
            //获取所有的收费类型
            getAllChargeType().then((res) => {
                if (res.data.data !== null) {
                    data.chargeTypeList = res.data.data
                }
            })
        })
        //定义跳转路由工具
        const router = useRouter();
        const data : DataAddCategory = reactive({
            categoryTree:props.categoryTree,
            category:{
                id:"",
                name:"",
                parentId:0,
                boolShare:true,
                boolCharge:false,
                boolAnonymous:true,
                chargeType: null,
                chargeModelId:"",
                iconUrl:"",
                tagIds:[]
            },
            checkprops : {
                checkStrictly : true
            },
            chargeModel:[
                {
                    id:"1",
                    name:"收费方式1"
                },
                {
                    id:"2",
                    name:"收费方式2"
                },
                {
                    id:"3",
                    name:"收费方式3"
                }
            ],
            templates:[],
            pId:[],
            tags:[],
            uploadUrl:uploadUrl.getUploadUrl(),
            limitCountImg:1,
            disabled:false,
            showBtnDealImg:true,
            noneBtnImg:false,
            dialogVisible:false,
            dialogImageUrl:"",
            fileList:[],
            chargeTypeList:[],
            submit : () => {
                data.category.parentId = data.pId[data.pId.length-1]
                if (data.category.id !==null &&data.category.id !== '') {
                    updateCategory(data.category).then((res) => {
                        console.log(res)
                    })
                }else{
                    add(data.category).then((res)=>{
                        if (res.data.data) {
                            
                        }else{
                            //登录失效提示
                            if (res.data.errorMessage) {
                                ElMessage.error(res.data.errorMessage)
                            }
                        }
                    })
                }
            },
            handleExceed: (file,fileList) => {
                ElMessage.error({
                    message: `上传图片数量超出限制！`,
                    type: "error",
                });
            },
            handleImgChange:(file,fileList) =>{
                data.noneBtnImg = fileList.length >= data.limitCountImg;
            },
            removePic:(file) =>{
                //移出列表中的图片
                if (data.fileList.length > 0) {
                    data.fileList.forEach((item,index) =>{
                        if (item.name == file.name) {
                            data.fileList.splice(index,1)
                        }
                    })
                }
                //移出列表中的图片
                data.category.iconUrl = ""
            },
            uploadSuccess : (response, file, fileList) =>{
                //赋值
                if (response.errorCode == 200 && response.data !='') {
                    //构建列表参数
                    let pic = {
                        name:'',
                        url:''
                    }
                    pic.name = file.name
                    pic.url = response.data
                    data.fileList.push(pic)
                    //构建列表参数
                    data.category.iconUrl = response.data
                }else{
                    ElMessage.error({
                        message: '图片上传失败！',
                        type:"error"
                    })
                }
            }

        })
        return {data};
    },
    components:{
    }
}) 
