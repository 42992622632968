
import { defineComponent, reactive } from 'vue'
import MainActiveTable from './main-active-table.vue'
import AddActive from './add-active.vue'
import DetailActive from './detail-active.vue'

interface DataMainActive{
    //当前页面类型
    currentType:string,
    //选中活动的id（用于页面传递）
    activeId:string,
    //改变当前页面
    changeType : (type:any) => void,
    //改变activeId
    changeActiveId : (activeId:string) => void
}
export default defineComponent({
    setup() {
        const data : DataMainActive = reactive({
            currentType : "table",
            activeId:"",
            changeType(type:any){
                if (type === 'add') {
                    data.activeId = ''
                }
                data.currentType = type
            },
            changeActiveId(activeId:string){
                data.activeId = activeId
            }
        })
        return {data}
    },
    components: {MainActiveTable, AddActive, DetailActive}
})
