
import { defineComponent, onMounted, reactive } from 'vue'
import {pageCouponBatch, delCouponBatch} from '@/http/api/currency'


interface DataBatchCoupon{
    //查询条件
    query:{
        pageSize:number
        pageNum:number
    }
    //数据量
    count:number
    //卡券批次
    couponBatchs:Array<Object>
    //跳转新增
    add:()=>void
    //跳转编辑
    changeType:(couponBatch:any)=>void
    //删除批次
    delBatch:(couponBatch:any)=>void
    //查询列表
    selectBatch:(val:number)=>void
}
export default defineComponent({
    emits:["changeType", "changeBatchId"],
    setup(props,{emit}) {
        onMounted(()=>{
            //分页查询批次信息
            pageCouponBatch(data.query).then((res) => {
                if (res.data.success && res.data.data !== null) {
                    data.couponBatchs = res.data.data.list
                    data.count = res.data.data.count
                }
            })
        })
        const data:DataBatchCoupon = reactive({
            query:{
                pageNum:1,
                pageSize:50
            },
            count:0,
            couponBatchs:[],
            add(){
                emit("changeType", "batchAdd")
            },
            changeType(couponBatch:any){
                //赋值
                emit("changeBatchId", couponBatch.id)
                //跳转
                emit("changeType", "batchUpdate")
            },
            delBatch(couponBatch:any){
                //删除批次
                delCouponBatch({id:couponBatch.id}).then((res) => {
                    if (res.data.data !== null && res.data.data) {
                        //重新请求页面数据
                        pageCouponBatch(data.query).then((res) => {
                            if (res.data.success && res.data.data !== null) {
                                data.couponBatchs = res.data.data.list
                            }
                        })
                    }
                })
            },
            selectBatch(val:number){
                //默认第一页
                data.query.pageNum = 1
                pageCouponBatch(data.query).then((res) => {
                    if (res.data.success && res.data.data !== null) {
                        data.couponBatchs = res.data.data.list
                        data.count = res.data.data.count
                    }
                })
            }
        })
        return {data}
    },
})
