
import { defineComponent, reactive, provide } from 'vue';
import homeHead from '@/components/index/home-head.vue';
import HomeNavigation from '@/components/index/home-navigation.vue';
import HomeMain from '@/components/index/home-main.vue';

interface DataHome {
    
}
export default defineComponent({
    setup(props, context) {
        const state = reactive({
            navIndex : 0
        })
        const choseNav = (param:number) =>{
            state.navIndex = param;
        };

        const data : DataHome = reactive({
            
        })
        return {data, choseNav, state}
    },
    //接收子组件参数
    components: { homeHead, HomeNavigation, HomeMain },
})
