import request from "../http";


/**
 * 新增活动
 * @param req 新增参数
 */
export const addActive = (req : any) => {
    return request({
        url: '/by-currency/active/add',
        method: 'post',
        data: req
    })
}

/**
 * 查询活动列表
 * @param req 查询参数
 */
 export const pageActive = (req : any) => {
    return request({
        url: '/by-currency/active/page',
        method: 'post',
        data: req
    })
}

/**
 * 修改活动信息
 * @param req 修改参数
 */
 export const updateActive = (req : any) => {
    return request({
        url: '/by-currency/active/update',
        method: 'post',
        data: req
    })
}

/**
 * 删除活动
 * @param req 删除参数
 */
 export const delActive = (req : any) => {
    return request({
        url: '/by-currency/active/del',
        method: 'post',
        data: req
    })
}

/**
 * 查询活动详情
 * @param req 活动id
 */
 export const detailActive = (req : any) => {
    return request({
        url: '/by-currency/active/detail',
        method: 'post',
        data: req
    })
}

/**
 * 获取活动的所有状态信息
 */
 export const getAllStatus = () => {
    return request({
        url: '/by-currency/active/getAllStatus',
        method: 'post',
        data: null
    })
}

/**
 * 获取活动的所有类型信息
 */
 export const getAllType = () => {
    return request({
        url: '/by-currency/active/getAllType',
        method: 'post',
        data: null
    })
}

/**
 * 获取活动可选的背景图片
 */
 export const getBackground = () => {
    return request({
        url: '/by-currency/active/background/getBackground',
        method: 'post',
        data: null
    })
}

/**
 * 获取活动可选的背景图片
 */
 export const queryCoupon = (req:any) => {
    return request({
        url: '/by-currency/coupon/query',
        method: 'post',
        data: req
    })
}


/**
 * 分页获取卡券批次信息
 */
 export const addCouponBatch = (req:any) => {
    return request({
        url: '/by-currency/coupon/batch/add',
        method: 'post',
        data: req
    })
}

/**
 * 获取活动可选的背景图片
 */
 export const getCouponType = () => {
    return request({
        url: '/by-currency/coupon/getAllType',
        method: 'post',
        data: null
    })
}

/**
 * 分页获取卡券批次信息
 */
 export const pageCouponBatch = (req:any) => {
    return request({
        url: '/by-currency/coupon/batch/page',
        method: 'post',
        data: req
    })
}

/**
 * 分页获取卡券批次信息
 */
 export const delCouponBatch = (req:any) => {
    return request({
        url: '/by-currency/coupon/batch/del',
        method: 'post',
        data: req
    })
}

/**
 * 获取卡券批次详情
 */
 export const detailCouponBatch = (req:any) => {
    return request({
        url: '/by-currency/coupon/batch/detail',
        method: 'post',
        data: req
    })
}

/**
 * 修改卡券批次
 */
 export const updateCouponBatch = (req:any) => {
    return request({
        url: '/by-currency/coupon/batch/update',
        method: 'post',
        data: req
    })
}
