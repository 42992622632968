
import { defineComponent, onMounted, reactive } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import {Plus,Delete} from '@element-plus/icons';
import {add,update,del,query,detail,getUnitTypes, getUnitDataTypes} from '@/http/api/tem'

interface DataTemplate{
    //已有的模板数组
    templates : Array<Object>;
    //新增的模板信息
    tem : {
        //模板id
        id:string
        //模板名字
        name:string,
        //是否为默认模板
        boolDefault:boolean,
        //模板简介
        des:string,
        //模板状态
        status:number,
        //模板单元数据
        units:Array<Object>
    },
    //模板状态选项
    temStatus:Array<Object>,
    //数据类型
    dataTypes:Array<Object>,
    //单元展示类型
    displayTypes:Array<Object>,
    //切换模板
    changeTem : (tem:any) => void,
    //新增模板
    addTem : () => void,
    //新增模板单元
    addUnit : () => void,
    //保存
    save : () => void,
    //删除
    del : () => void,
    //删除单元数据
    delUnit : (index:number) => void,
}
export default defineComponent({
    setup() {
        //初始化页面数据
        onMounted(()=>{
            query({}).then((res)=>{
                if (res.data.data.length !==0) {
                    data.templates = res.data.data
                    data.tem = res.data.data[0]   
                }
            }),
            getUnitTypes().then((res)=>{
                if (res.data.data.length !==0) {
                    data.displayTypes = res.data.data  
                }
            })
            getUnitDataTypes().then((res) =>{
                if (res.data.data.lenght !==0) {
                    data.dataTypes = res.data.data
                }
            })
        })
        const data : DataTemplate = reactive({
            templates : [
                {
                    id:"",
                    name:""
                }
            ],
            tem:{
                id:"",
                name:"",
                boolDefault:false,
                des:"",
                status:0,
                units:[]
            },
            temStatus:[
                {label:0,name:"已上架"},
                {label:1,name:"已下架"}
            ],
            dataTypes:[],
            displayTypes:[
                {type:"div",name:"div"},
                {type:"textarea",name:"textarea"},
                {type:"input",name:"input"},
                {type:"pic",name:"pic"},
            ],
            changeTem : (tem:any) => {
                data.tem = tem
            },
            addTem : () => {
                data.tem ={
                    id:"",
                    name:"123",
                    boolDefault:false,
                    des:"",
                    status:1,
                    units:[]
                }
            },
            addUnit : () => {
                    let unit = {
                    id:null,
                    name:"",
                    orderNum:1,
                    unitPType:"div",
                    unitDType:"div"
                }
                if (data.tem.units===null) {
                    data.tem.units=[]
                }
                    data.tem.units.push(unit)
            },
            save : () => {
                if (data.tem.id==="") {
                    add(data.tem).then((res)=>{
                        if (res.data.data) {
                            ElMessage({
                                message:"新增成功！",
                                type:'success'
                            })   
                        }
                    })   
                }else{
                    update(data.tem).then((res)=>{
                        if (res.data.data) {
                            ElMessage({
                                message:"修改成功！",
                                type:'success'
                            })   
                        }
                    })
                }
            },
            del : () => {
                //提示是否删除
                ElMessageBox.confirm(
                    '模板'+data.tem.name+'将被删除!',
                    '删除',
                    {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                ).then(()=>{
                    let delParam = {
                        id:data.tem.id
                    }
                    del(delParam).then((res) => {
                        if (res.data.data) {
                            ElMessage({
                                message:"删除成功！",
                                type:'success'
                            })
                        }
                    })
                })
            },
            delUnit : (index:number) => {
                data.tem.units.splice(index,1)
            }
        })
        return {data}
    },
    components : {Plus,Delete}
})
