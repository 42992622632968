import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_active_table = _resolveComponent("main-active-table")!
  const _component_add_active = _resolveComponent("add-active")!
  const _component_detail_active = _resolveComponent("detail-active")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.data.currentType === 'table')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_main_active_table, {
            onChangeType: _ctx.data.changeType,
            onChangeActiveId: _ctx.data.changeActiveId
          }, null, 8, ["onChangeType", "onChangeActiveId"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.currentType === 'add')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_add_active, {
            onChangeType: _ctx.data.changeType,
            activeId: _ctx.data.activeId
          }, null, 8, ["onChangeType", "activeId"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.currentType === 'update')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_add_active, {
            activeId: _ctx.data.activeId,
            onChangeType: _ctx.data.changeType
          }, null, 8, ["activeId", "onChangeType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.currentType === 'detail')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_detail_active, {
            activeId: _ctx.data.activeId,
            onChangeType: _ctx.data.changeType
          }, null, 8, ["activeId", "onChangeType"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}