
import { defineComponent, reactive } from 'vue';
import MainVideoTable from './main-video-table.vue';


interface DataVideo {

}
export default defineComponent({
    components:{MainVideoTable},
    setup() {
        const data : DataVideo = reactive({
            
        })
        return {data}
    },
})
