
import { number } from 'echarts'
import { defineComponent, reactive } from 'vue'

interface DataTaskList {
    //内容列表
    tasks : Array<Object>;
}
export default defineComponent({
    setup() {
        const data : DataTaskList = reactive({
            tasks : [
                {id:1,name:'模拟栏目一'},
                {id:2,name:'模拟栏目二'},
                {id:3,name:'模拟栏目三'},
                {id:4,name:'模拟栏目四'}
            ]
        })
        return {data}
    },
})
