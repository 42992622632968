import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8859f866"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_head = _resolveComponent("home-head")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_home_navigation = _resolveComponent("home-navigation")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_home_main = _resolveComponent("home-main")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_home_head)
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, { class: "index-inside" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_aside, { width: "200px" }, {
              default: _withCtx(() => [
                _createVNode(_component_home_navigation, { onChoseNav: _ctx.choseNav }, null, 8, ["onChoseNav"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createVNode(_component_home_main, {
                  navIndex: _ctx.state.navIndex
                }, null, 8, ["navIndex"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}