
import { defineComponent, onMounted, reactive } from 'vue'

interface DataMainPoint{

}
export default defineComponent({
    setup(props, {emit}) {
        onMounted(() => {
            console.log(props)
        })
        const data : DataMainPoint = reactive({

        })
        return {data}
    },
})
