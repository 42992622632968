
import { defineComponent, onMounted, reactive } from 'vue'
import {uploadUrl} from '@/utils/url'
import { ElMessage } from 'element-plus';
import {Plus} from '@element-plus/icons';
import {addActive, detailActive, getAllType, getBackground, updateActive, pageCouponBatch} from '@/http/api/currency'

interface DataAddActive{

    //所有的活动类型
    activeTypes:Array<Object>
    //修改时的活动id
    activeId:string,
    //活动
    active:{
        backgroundUrl:string,
        startTime:string,
        endTime:string,
        sendBatches:Array<string>
    },
    //活动对应的时间
    time:any
    //可以选择的背景图片地址
    backgroundUrls:Array<string>
    //上传图片的url地址
    uploadUrl:string,
    //选中的图片的下标
    checkIndex:number,
    //所有的卡券批次
    allBatchs:Array<any>
    //选中的批次卡券
    checkBatch:Array<any>
    //提交
    submit:()=>void;
    //图片上传成功回调
    uploadSuccess:(response:any,file:any,fileList:any) => void;
    //选择背景图片
    checkUrl:(index:number) => void,
    //跳转主页
    toTable:()=>void
}
export default defineComponent({
    props:[
        'activeId'
    ],
    emits:["changeType"],
    setup(props,{emit}) {
        onMounted(()=>{
            data.activeId = props.activeId
            //获取所有活动类型
            getAllType().then((res) => {
                if(res.data.success && res.data.data !== null){
                    data.activeTypes = res.data.data
                }
            })
            //获取活动可用的所有背景图片
            getBackground().then((res) => {
                if(res.data.success && res.data.data !== null){
                    let urls = res.data.data.backgroundUrls
                    data.backgroundUrls = urls
                    if (props.activeId !==null && props.activeId !== '') {
                        //获取活动详情
                        detailActive({id:props.activeId}).then((res) => {
                            if (res.data.success && res.data.data !== null) {
                                data.active = res.data.data
                                data.time[0] = new Date(res.data.data.startTime)
                                data.time[1] = new Date(res.data.data.endTime)
                                for (let index = 0; index < urls.length; index++) {
                                    if (urls[index] === data.active.backgroundUrl) {
                                        data.checkIndex = index   
                                    }
                                }
                                if (res.data.data.batchList !== null && res.data.data.batchList.length > 0) {
                                    for (let index = 0; index < res.data.data.batchList.length; index++) {
                                        data.checkBatch.push(res.data.data.batchList[index].batch)
                                    }
                                }
                            }
                        })
                    }
                }
            })
            //获取正在进行中的卡券批次
            let req = {
                pageNum : 1,
                pageSize : 50,
                status : 1
            }
            pageCouponBatch(req).then((res) => {
                if (res.data.success && res.data.data !== null) {
                    data.allBatchs = res.data.data.list
                }
            })
        })
        const data : DataAddActive = reactive({
            activeTypes:[],
            activeId:"",
            active:{
                backgroundUrl:'',
                startTime:'请输入开始时间',
                endTime:'请输入开始时间',
                sendBatches:[]
            },
            time:[],
            backgroundUrls:[],
            uploadUrl:uploadUrl.getUploadUrl(),
            checkIndex:0,
            allBatchs:[],
            checkBatch:[],
            uploadSuccess : (response, file, fileList) =>{
                //赋值
                if (response.errorCode == 200 && response.data !='') {
                    //构建列表参数
                    let pic = {
                        name:'',
                        url:''
                    }
                    pic.name = file.name
                    pic.url = response.data
                    data.backgroundUrls.push(response.data)
                    //自动选中
                    data.checkIndex = data.backgroundUrls.length -1
                }else{
                    ElMessage.error({
                        message: '图片上传失败！',
                        type:"error"
                    })
                }
            },
            //切换背景图片
            checkUrl(index:number){
                data.checkIndex = index
            },
            submit(){
                //获取背景图片
                let url = data.backgroundUrls[data.checkIndex]
                data.active.backgroundUrl = url
                if(data.time !== null && data.time.length >=2){
                    data.active.startTime = data.time[0].getTime()
                    data.active.endTime = data.time[1].getTime()
                }
                if (data.checkBatch !== null && data.checkBatch.length > 0) {
                    data.active.sendBatches = data.checkBatch
                }
                console.log(data.active)
                if (data.activeId === null || data.activeId === '') {
                    addActive(data.active).then((res)=>{
                        if (res.data.success && res.data.data !== null) {
                            ElMessage({
                                        type: 'success',
                                        message: '修改成功',
                                    })
                            emit("changeType","table")
                        }
                    })
                }else{
                    updateActive(data.active).then((res) => {
                        if (res.data.success && res.data.data !== null) {
                            ElMessage({
                                        type: 'success',
                                        message: '修改成功',
                                    })
                            emit("changeType","table")
                        }
                    })
                }
            },
            toTable(){
                emit("changeType","table")
            }
        })
        return {data}
    },
    components : {Plus}
})
