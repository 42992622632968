
import { defineComponent, reactive } from 'vue';
import {ChatLineSquare,Message,Warning} from '@element-plus/icons'

interface DataHead {
    //警告、消息、邮件未读数量
    msgNum : Object;
    //消息提示信息
    content : {
            //警告的消息提示
            warningMsg : string,
            //消息的消息提示
            messageMsg : string,
            //邮件的消息提示
            emailMsg : string
            };
    //管理员信息
    admin : Object
    //获取消息提示
    getTopMsg : (warningNum:number,messageNum:number,emailNum:number) => void
}
export default defineComponent({
    components : {ChatLineSquare,Message,Warning},
    setup() {

        const data : DataHead = reactive({
            msgNum : {
                //警告未读数量
                warningNum : 18,
                //消息未读数量
                messageNum : 20,
                //邮件未读数量
                emailNum : 500
            },
            content : {
                //警告的消息提示
                warningMsg : '全部已读',
                //消息的消息提示
                messageMsg : '全部已读',
                //邮件的消息提示
                emailMsg : '全部已读'
            },
            //管理员账号信息
            admin : {
                id : 1,
                name : '张三',
                avatar : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
            },
            //获取消息提示
            getTopMsg : (warningNum:number,messageNum:number,emailNum:number) =>{
                console.log('触发计算时间')
                if(warningNum>0){
                    data.content.warningMsg = '有'+warningNum+'个警告信息未读'
                }else{
                    data.content.warningMsg = '全部已读'
                }
                if(messageNum>0){
                    data.content.messageMsg = '有'+messageNum+'条消息未读'
                }else{
                    data.content.messageMsg = '全部已读'
                }
                if(emailNum>0){
                    data.content.emailMsg = '有'+emailNum+'封邮件未读'
                }else{
                    data.content.emailMsg = '全部已读'
                }
            }
        })
        return {data}
    },
})
