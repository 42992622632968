import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_active = _resolveComponent("main-active")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_main_coupon = _resolveComponent("main-coupon")!
  const _component_main_point = _resolveComponent("main-point")!
  const _component_main_member = _resolveComponent("main-member")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_scrollbar, { height: "700px" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tabs, {
          class: "tag_content",
          type: "border-card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, { label: "活动" }, {
              default: _withCtx(() => [
                _createVNode(_component_main_active)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "卡券" }, {
              default: _withCtx(() => [
                _createVNode(_component_main_coupon)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "积分" }, {
              default: _withCtx(() => [
                _createVNode(_component_main_point)
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { label: "会员" }, {
              default: _withCtx(() => [
                _createVNode(_component_main_member)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}