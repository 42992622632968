import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "top_card" }, {
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.tasks, (task, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "top_card_li",
            key: task
          }, _toDisplayString(index+1) + "." + _toDisplayString(task.name), 1))
        }), 128))
      ])
    ]),
    _: 1
  }))
}