
import { defineComponent, onMounted, reactive } from 'vue'
import {getAllStatus, pageActive} from '@/http/api/currency'
import { ElMessage, ElMessageBox } from 'element-plus'
import {delActive} from '@/http/api/currency'

interface DataMainActiveTable{
    //查询条件
    query:{
        pageNum:number,
        pageSize:number,
    },
    //活动列表
    actives:Array<Object>,
    //所有状态信息
    allStatus:Array<Object>,
    //活动总数量
    count:Number,

    //搜索
    selectActive : (val:number) => void,
    //跳转新增页面
    add : () => void,
    //跳转详情
    handleDetail:(active:any) => void,
    //跳转编辑
    handleEdit:(active:any) => void,
    //删除
    handleDel:(active:any) => void,
}
export default defineComponent({
    emits:["changeType","changeActiveId"],
    setup(props,{emit}) {
        //页面初始化内容
        onMounted(() => {
            console.log(props)
            //获取所有的活动状态
            getAllStatus().then((res)=>{
                data.allStatus = res.data.data
            })
            //查询对应的活动信息
            pageActive(data.query).then((res) => {
                if(res.data.success && res.data.data !== null){
                    data.actives = res.data.data.list
                }
            })
        })
        const data : DataMainActiveTable = reactive({
            query:{
                pageNum:1,
                pageSize:10
            },
            actives:[],
            allStatus:[],
            count:0,
            selectActive(val:number){
                data.query.pageNum = val
                //查询对应的活动信息
                pageActive(data.query).then((res) => {
                    if(res.data.success && res.data.data !== null){
                        data.actives = res.data.data.list
                    }
                })
            },
            //跳转新增页面
            add(){
                emit("changeType", "add")
            },
            handleDetail(active:any){
                //修改父组件中的活动id
                emit("changeActiveId",active.id)
                //跳转
                emit("changeType","detail")
            },
            handleEdit(active:any){
                //修改父组件中的活动id
                emit("changeActiveId",active.id)
                //跳转
                emit("changeType","update")
            },
            handleDel(active:any){
                ElMessageBox.confirm(
                    '是否删除活动',
                    '警告',
                    {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                    }
                )
                    .then(() => {
                        delActive({id:active.id}).then((res) => {
                            if (res.data.success) {
                                //刷新列表
                                pageActive(data.query).then((res) => {
                                    if(res.data.success && res.data.data !== null){
                                        data.actives = res.data.data.list
                                    }
                                })
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                })
                            }else{
                                ElMessage({
                                    type: 'error',
                                    message: '删除失败',
                                })
                            }
                        })
                    })
                    .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '取消',
                        })
                    })
            }
        })
        return {data}
    },
})
