import request from "../http";


/**
 * 新增模板
 * @param param 新增参数
 * @returns id
 */
export const add = (param:any) =>{
    return request({
        url : "/by-content/template/add",
        method : "post",
        data : param
    })
}

/**
 * 更新模板
 * @param param 更新参数
 * @returns 是否成功
 */
export const update = (param:any) => {
    return request({
        url : "/by-content/template/update",
        method : "post",
        data : param
    })
}

/**
 * 删除模板
 * @param param 删除参数
 * @returns 是否成功
 */
 export const del = (param:any) => {
    return request({
        url : "/by-content/template/del",
        method : "post",
        data : param
    })
}

/**
 * 获取模板
 * @param param 获取模板
 * @returns 模板详情
 */
 export const detail = (param:any) => {
    return request({
        url : "/by-content/template/detail",
        method : "post",
        data : param
    })
}

/**
 * 查询模板
 * @param param 查询参数
 * @returns 模板数据
 */
 export const query = (param:any) => {
    return request({
        url : "/by-content/template/query",
        method : "post",
        data : param
    })
}

/**
 * 查询模板
 * @param param 查询参数
 * @returns 模板数据
 */
 export const getUnitTypes = () => {
    return request({
        url : "/by-content/template/getUnitTypes",
        method : "post",
        data : null
    })
}

/**
 * 查询模板
 * @param param 查询参数
 * @returns 模板数据
 */
 export const getUnitDataTypes = () => {
    return request({
        url : "/by-content/dataType/query",
        method : "post",
        data : null
    })
}