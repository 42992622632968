import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_video_table = _resolveComponent("main-video-table")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "main_content_card" }, {
    default: _withCtx(() => [
      _createVNode(_component_main_video_table)
    ]),
    _: 1
  }))
}