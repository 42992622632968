
import { ElMessageBox } from 'element-plus';
import { defineComponent, onMounted, reactive } from 'vue';
import { batchAddTag, updateTag, delTag, detailTag, queryTag } from '@/http/api/tag';
import { ElMessage } from 'element-plus';
import addTag from './add-tag.vue'

interface DataTag {
    queryTagsParam : Object;
    //标签数组
    tags : Array<Object>;
    //新增标签
    addTag : () => void;
    //新增标签的抽屉
    addDrawer : boolean;
    //是否显示dialog
    displayDialog : boolean;
    //点击标签显示详情
    detail : (tag : Object) => void;
    //删除标签
    delTag : (tagId:number,tagName:string) => void;
    //删除标签参数
    delTagParam : {
        id : number
    };
    //标签详情
    tagDetail : Object;
    //关闭标签详情页
    cancel : () => void;
    //点击编辑按钮
    update : () => void;
    //内容框是否可以输入
    inputDisabled : boolean;
    //标签类型数组
    types : Array<Object>;
    //保存编辑后的标签信息
    save : () => void;
    //关闭抽屉
    closeDrawer : () => void;
    
}
export default defineComponent({
    setup() {
        onMounted(() => {
            queryTag(data.queryTagsParam).then((res) => {
                data.tags = res.data.data
            }).catch((e) => {
                ElMessage({
                            showClose: true,
                            message: e.errorMessage,
                            type: 'error',
                        })
            })
        })
        const data : DataTag = reactive({
            queryTagsParam: {},
            tags : [
                
            ],
            addTag : () => {
                data.addDrawer = true
            },
            addDrawer : false,
            displayDialog : false,
            detail : (tag:Object) => {
                data.displayDialog = true;
                data.inputDisabled = false;
                data.tagDetail = tag
            },
            delTag : (tagId:number,tagName:string) => {
                ElMessageBox.confirm('将要删除：'+tagName+'标签','删除标签',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消'
                }).then(()=>{
                    data.delTagParam.id = tagId;
                        delTag(data.delTagParam).then((res) => {
                            ElMessage({
                                message: '删除成功',
                                type: 'success',
                            })
                        }).catch((e) => {
                            ElMessage({
                                showClose: true,
                                message: e.errorMessage,
                                type: 'error',
                            })
                        })
                }).catch(()=>{
                    ElMessage({
                        type: 'info',
                        message: '取消',
                    })
                })
            },
            delTagParam : {
                id : 0
            },
            tagDetail : {},
            cancel : () => {
                data.displayDialog = false;
                data.inputDisabled = false;
                data.tagDetail = new Object;
            },
            update : () => {
                data.inputDisabled = true;
            },
            inputDisabled :false,
            types : [
                {id:1,name:'人员'},
                {id:0,name:'内容'}
            ],
            save : () => {
                updateTag(data.tagDetail).then((res) => {
                    if (res.data) {
                        data.displayDialog = false
                    }
                }).catch((e) => {
                    ElMessage({
                                showClose: true,
                                message: e.errorMessage,
                                type: 'error',
                            })
                })
            },
            
            closeDrawer : () => {
                data.addDrawer = false
            }
            
        });
        return {data}
    },
    components : {addTag}
})
