
import { defineComponent } from 'vue'
import MainDataTask from './main-data-task.vue'
import MainDataTaskList from './main-data-task-list.vue'
import MainDataView from './main-data-view.vue'

export default defineComponent({
    setup() {
        
    },
    components : {MainDataTask, MainDataTaskList, MainDataView}
})
