
import { defineComponent, reactive, onMounted } from 'vue';
import { getAllStatus, query, del, approve, changeStatus, contentDetail } from '@/http/api/content';

import {ElMessageBox, ElMessage} from 'element-plus';

interface DataContentDetail {
    //内容id
    contentId: string,
    //内容详情
    content: {
        category:Object,
        contentTemUnits:Array<Object>,
    },
    //审批
    approveDialog : boolean;
    //要审批的内容id
    approveContent : Object;
    //全部审批结果
    approveRes : Array<any>;
    //审批
    handleApprove : (content:any) => void;
    //审批
    approve : () => void;
    //取消审批时
    cancelApprove : () => void;
    //关闭审批界面
    handleClose : () => void;

}

export default defineComponent({
    props:[
        'contentId'
    ],
    setup(props,{emit}) {
        onMounted(() => {
            data.contentId = props.contentId;
            //获取内容详情
            if(data.contentId !== ''){
                contentDetail({id:data.contentId}).then((res) => {
                    data.content = res.data.data
                })
            }
        })
        const data : DataContentDetail = reactive({
            contentId: '',
            content:{
                category:{},
                contentTemUnits:[]
            },
            approveDialog:false,
            approveContent:{},
            approveRes:[
                {
                    value: false,
                    label: "拒绝"
                },
                {
                    value: true,
                    label: "通过"
                }
            ],
            //审批
            handleApprove : (content:any) => {
                data.approveDialog = true
                data.approveContent = {
                    id: content.id, 
                    title: content.title
                }
            },
            approve : () => {
                approve(data.approveContent).then((res) => {
                    if (res.data.data) {
                        data.approveContent = {},
                        data.approveDialog = false
                    }else{
                        ElMessage({
                            type:'error',
                            message: '审批失败'
                        })
                    }
                })
            },
            cancelApprove : () => {
                data.approveDialog = false,
                data.approveContent = {}
            },
            handleClose : () => {
                data.approveContent = {},
                data.approveDialog = false
            }
        })
        return {data}
    },
})
