
import { defineComponent, onMounted, reactive } from 'vue'
import {queryCoupon,addCouponBatch,delCouponBatch, detailCouponBatch,updateCouponBatch} from '@/http/api/currency'
import { ElMessage, ElMessageBox } from 'element-plus'
import { time } from 'echarts'

interface DataAddBatchCoupon{
    //是否显示dialog
    dialogVisible:boolean
    //使用时间选择框
    timeDialog:boolean
    //卡券批次id
    batchId:''
    //卡券批次
    batch:any
    //时间
    time:Array<any>
    //正在处理中的使用时间
    useTime:Array<any>
    //正在处理中的卡券配置信息
    couponConfig:any
    //所有的卡券信息
    allCoupons:Array<any>
    //选中的卡券信息
    chooseCoupons:Array<any>
    //dialog中选中的卡券
    dialogChooseCouponIds:Array<string>

    //跳转列表
    changeType:()=>void
    //打开dialog
    openCouponsDialog:()=>void,
    //打开使用时间选择dialog
    openTimeDialog:(coupon:any)=>void,
    //选择卡券
    addCouponIds:(coupon:any)=>void,
    //新增卡券信息
    addCoupons:()=>void
    //取消选择时间
    timeChooseCancel:()=>void
    //确定选择时间
    timeChoose:()=>void
    //关闭时间选择dialog
    closeTimeDialog:()=>void
    //取消新增
    cancel:()=>void
    //提交
    submit:()=>void
}
export default defineComponent({
    props:["batchId"],
    emits:["changeType"],
    setup(prop,{emit}) {
        onMounted(()=>{
            data.batchId = prop.batchId
            //获取批次详情
            if (prop.batchId !== null && prop.batchId !== '') {
                detailCouponBatch({id : prop.batchId}).then((res) =>{
                    if (res.data.success && res.data.data !== null) {
                        //处理时间
                        data.time.push(new Date(res.data.data.startTime))
                        data.time.push(new Date(res.data.data.endTime))
                        console.log(data.time)
                        data.batch = res.data.data
                        //设置选中的卡券信息
                        data.chooseCoupons = res.data.data.coupons
                        //处理选中卡券中的时间
                        if (data.chooseCoupons !== null && data.chooseCoupons.length > 0) {
                            for (let index = 0; index < data.chooseCoupons.length; index++) {
                                data.chooseCoupons[index].useStartTime = new Date(data.chooseCoupons[index].useStartTime).getTime()
                                data.chooseCoupons[index].useEndTime = new Date(data.chooseCoupons[index].useEndTime).getTime()
                            }
                        }
                    }
                })
            }
            //查询卡券信息
            queryCoupon({}).then((res) => {
                if (res.data.success && res.data.data !== null) {
                    data.allCoupons = res.data.data
                }
            })
        })
        const data:DataAddBatchCoupon = reactive({
            dialogVisible:false,
            timeDialog:false,
            batchId:'',
            batch:{},
            time:[],
            useTime:[],
            couponConfig:{},
            allCoupons:[],
            chooseCoupons:[],
            dialogChooseCouponIds:[],
            changeType(){
                emit("changeType","couponBatch")
            },
            openCouponsDialog(){
                data.dialogVisible = true
            },
            openTimeDialog(coupon:any){
                data.timeDialog = true
                //处理默认时间
                if (coupon.useStartTime !== null &&coupon.useStartTime !== ''
                    &&coupon.useEndTime !== null &&coupon.useEndTime !=='') {
                    data.useTime.push(new Date(coupon.useStartTime))
                    data.useTime.push(new Date(coupon.useEndTime))
                }
                data.couponConfig = coupon
            },
            addCouponIds(coupon:any){
                if(data.dialogChooseCouponIds.includes(coupon.id)){
                    data.dialogChooseCouponIds.splice(data.dialogChooseCouponIds.indexOf(coupon.id),1)
                }else{
                    data.dialogChooseCouponIds.push(coupon.id)
                }
            },
            addCoupons(){
                console.log(data.dialogChooseCouponIds)
                //设置选中的数据为空
                data.chooseCoupons = []
                //处理选中的数据
                if (data.dialogChooseCouponIds.length > 0) {
                    for (let index = 0; index < data.allCoupons.length; index++) {
                        let coupon = data.allCoupons[index]
                        if (data.dialogChooseCouponIds.includes(coupon.id)) {
                            data.chooseCoupons.push(coupon)
                        }
                    }
                }
                data.dialogVisible = false
            },
            timeChoose(){
                //处理时间
                if (data.useTime.length > 0) {
                    data.couponConfig.useStartTime = data.useTime[0].getTime()
                    data.couponConfig.useEndTime = data.useTime[0].getTime()
                }
                //处理卡券组中的数据
                for (let index = 0; index < data.chooseCoupons.length; index++) {
                    if (data.chooseCoupons[index].id === data.couponConfig.id) {
                        data.chooseCoupons[index] = data.couponConfig
                    }
                }
                data.timeDialog = false
                console.log(data.chooseCoupons)
            },
            timeChooseCancel(){
                data.useTime = []
                data.couponConfig = {}
                data.timeDialog = false
            },
            closeTimeDialog(){
                data.useTime = []
                data.couponConfig = {}
                data.timeDialog = false
            },
            cancel(){
                //初始化数据
                data.dialogVisible=false
                data.timeDialog=false,
                data.batchId='',
                data.batch={},
                data.time=[],
                data.useTime=[],
                data.couponConfig={},
                data.allCoupons=[],
                data.chooseCoupons=[],
                data.dialogChooseCouponIds=[]
                //跳转页面
                emit("changeType","couponBatch")
            },
            submit(){
                //处理卡券数据
                console.log(data.chooseCoupons)
                if (data.chooseCoupons.length > 0) {
                    let batchCouponList = []
                    for (let index = 0; index < data.chooseCoupons.length; index++) {
                        let c = data.chooseCoupons[index]
                        let batchCoupon = {
                            couponId:c.id,
                            stock:c.totalStock,
                            totalStock:c.totalStock,
                            useStartTime:c.useStartTime,
                            useEndTime:c.useEndTime
                        }
                        batchCouponList.push(batchCoupon)
                    }
                    data.batch.batchCoupons = batchCouponList
                }
                //处理时间
                if (data.time !== null && data.time.length > 0) {
                    data.batch.startTime = data.time[0].getTime()
                    data.batch.endTime = data.time[1].getTime()
                }
                console.log(data.batch);
                console.log(data.batchId)
                if (data.batchId !== null && data.batchId !== '') {
                    //修改
                    updateCouponBatch(data.batch).then((res) => {
                        if (res.data.data) {
                            ElMessage({
                                        type: 'success',
                                        message: '修改成功',
                                    })
                            //跳转   
                            emit("changeType","couponBatch")     
                        }
                    })
                }else{
                    //新增
                    addCouponBatch(data.batch).then((res) =>{
                        if (res.data.data) {
                            ElMessage({
                                        type: 'success',
                                        message: '新增成功',
                                    })
                            //跳转   
                            emit("changeType","couponBatch")     
                        }
                    })
                }
            }
        })
        return {data}
    },
})
