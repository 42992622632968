import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, {
      id: "data_view_chart",
      class: "data_view"
    }),
    _createVNode(_component_el_card, {
      id: "data_category_pie_chart",
      class: "data_category_pie"
    })
  ]))
}