
import { defineComponent, onMounted, reactive } from 'vue';
import {ElMessageBox, ElMessage} from 'element-plus';
import {del} from '@/http/api/category'


interface DataDetailCategory{
    //栏目详情
    categoryDetail:any,
    //栏目模板
    tem:{
        name:string
    },
    //栏目标签
    tags:Array<any>,
    //删除
    del : () => void,
    //编辑
    editCategory : () => void
}
export default defineComponent({
    props:[
        "detail"
    ],
    setup(props,{emit}) {
        onMounted(() => {
            data.categoryDetail = props.detail;
            console.log(data.categoryDetail)
            if (props.detail.template != null) {
                data.tem = props.detail.template
            };
            if (props.detail.tags != null && props.detail.tags.length > 0) {
                data.tags = props.detail.tags
            }
        })
        const data : DataDetailCategory = reactive({
            categoryDetail:{},
            tem:{
                name:""
            },
            tags:[],
            del : () => {
                console.log("111111111")
                ElMessageBox.confirm(
                    '确定要删除'+data.categoryDetail.name+'吗？',
                    '删除',
                    {
                        confirmButtonText : '确定',
                        cancelButtonText : '取消',
                        type : 'warning'
                    }
                ).then(() => {
                    del({id:data.categoryDetail.id}).then((res) => {
                        if (res.data.data) {
                            ElMessage({
                                type:'success',
                                message: '删除成功'
                            })
                            //调回栏目页并刷新数据
                            emit("flashCategory")
                        }else{
                            ElMessage({
                                type:'error',
                                message: '删除失败'
                            })
                        }
                    })
                }).catch(()=>{})
            },
            editCategory : () => {
                emit("edit")
            }
        })
        return {data}
    },
})
